import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";


const FileUpload = () => {
  const [file, setFile] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFile) => {
      setFile(
        Object.assign(acceptedFile[0], {
          preview: URL.createObjectURL(acceptedFile[0]),
        })
      );
    },
  });

  useEffect(() => {
    if (file) {
      let formData = new FormData();

      formData.append("assets", file, file.name);

      axios({
        method: "post",
        url: `https://2fe8p9b1n1.execute-api.eu-west-2.amazonaws.com/dev/files`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    }
  }, [file]);

  //   const handleSubmit = async () => {
  //     if (file) {
  //       uploadFile({
  //         variables: { file },
  //         // add the rest of the form fields to this query
  //         // refetchQueries: [{ query: FileQuery, variables: file }],
  //       });
  //       setFile({});
  //       // post message on form
  //       console.log("Uploaded successfully: ", file);
  //     } else {
  //       // return alertk
  //       console.log("No files to upload");
  //     }
  //   };

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some file here, or click to select file</p>
    </div>
  );
};

export default FileUpload;
